import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';
import { Popover, Typography } from 'antd';
import styled from 'styled-components';

import { useMnemonicPhraseStore } from '@/entities/mnemonic/store/mnemonic-phrase.store';
import { Button } from '@/shared/components';
import { Container, Row } from '@/shared/components/layout';
import { COLORS } from '@/shared/constants/colors';
import { CryptoCore } from '@/shared/lib/secure-json/core/crypto-core';
import { useRegistrationStore } from '@/shared/store/registration.store';
import { Title } from '@/shared/uikit/Title';
import { log } from '@/shared/utils/log';
import { copy } from '@/shared/utils/misc';
import { BlockMnemonicPhrase } from '@/widgets/mnemonic-phrase/block-mnemonic-phrase';

interface ISaveMnemonic {
  confirmSavedPhrase: boolean;
  confirmImpossibleToRecover: boolean;
  setConfirmSavedPhrase: (phrase: boolean) => void;
  setConfirmImpossibleToRecover: (phrase: boolean) => void;
  changeStep: () => void;
}

export const SaveMnemonic: FC<ISaveMnemonic> = ({
  setConfirmSavedPhrase,
  confirmSavedPhrase,
  confirmImpossibleToRecover,
  setConfirmImpossibleToRecover,
  changeStep,
}) => {
  const { t } = useTranslation('signup');

  const [generatedPhrase, setGeneratedPhrase] = useState<string[]>([]);
  const { setMnemonicPhrase, mnemonicPhrase } = useMnemonicPhraseStore();
  const { setPublicKey } = useRegistrationStore();

  const saveMnemonicPhrase = async () => {
    setMnemonicPhrase(generatedPhrase);
    changeStep();
  };

  const generateMnemonicPhrase = async () => {
    const cryptoCore = new CryptoCore();
    const mnemonic =
      Array.isArray(mnemonicPhrase) && mnemonicPhrase.length
        ? mnemonicPhrase.join(' ')
        : cryptoCore.mnemonic.generateMnemonic();
    const keyPair = await cryptoCore.mnemonic.mnemonicToKeyPair(mnemonic);

    return { mnemonic: mnemonic.split(' '), publicKey: keyPair.publicKey };
  };

  useEffect(() => {
    generateMnemonicPhrase()
      // eslint-disable-next-line promise/always-return
      .then((phrase: { mnemonic: string[]; publicKey: string }) => {
        setGeneratedPhrase(phrase.mnemonic);
        setPublicKey(phrase.publicKey);
      })
      .catch((error) => {
        log.error('Mnemonic phrase error', error);
      });
  }, []);

  return (
    <>
      <Body>
        <Title level={3}>{t('saveMnemonic.title')}</Title>

        <Container marginTop={4}>
          <Typography.Paragraph style={{ fontSize: 12 }}>
            {t('saveMnemonic.paragraph1')}
          </Typography.Paragraph>
          <Typography.Paragraph>
            {t('saveMnemonic.paragraph2')}
          </Typography.Paragraph>
        </Container>

        <ImportantContainer>
          <Typography.Text style={{ fontSize: 16, fontWeight: 700 }}>
            {t('saveMnemonic.important.title')}
          </Typography.Text>
          <Typography.Text>
            {t('saveMnemonic.important.description')}
          </Typography.Text>
        </ImportantContainer>

        {generatedPhrase && (
          <Row marginTop={24} justifyContent="center">
            <BlockMnemonicPhrase phrase={generatedPhrase} />
          </Row>
        )}

        <Row justifyContent="center" marginTop={24}>
          <Popover content={<span>Copied</span>} trigger="click">
            <Copy
              data-testid={'copyMnemonic'}
              onClick={() => copy(generatedPhrase.join(' '))}
            >
              <Typography.Link underline>
                {t('saveMnemonic.copyMnemonicButton')}
              </Typography.Link>
            </Copy>
          </Popover>
        </Row>

        <Row justifyContent="flex-start" marginTop={24}>
          <Checkbox
            data-testid={'confirmSavedPhrase'}
            onChange={() => setConfirmSavedPhrase(!confirmSavedPhrase)}
          >
            {t('saveMnemonic.saveConformationCheckbox')}
          </Checkbox>
        </Row>
        <Row justifyContent="center" marginTop={10}>
          <Checkbox
            data-testid={'confirmImpossibleToRecover'}
            onChange={() =>
              setConfirmImpossibleToRecover(!confirmImpossibleToRecover)
            }
          >
            {t('saveMnemonic.impossibleToRecoverConfirmationCheckbox')}
          </Checkbox>
        </Row>
      </Body>

      <ActionContainer>
        <Button
          data-testid={'continueBtn'}
          text={t('common.continueBtn')}
          disabled={!confirmSavedPhrase || !confirmImpossibleToRecover}
          onClick={() => saveMnemonicPhrase()}
        >
          {t('common.continueBtn')}
        </Button>
      </ActionContainer>
    </>
  );
};

const ImportantContainer = styled(Container)`
  padding: 8px 16px;
  margintop: 8px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  background: ${COLORS.colorGoldBg};
  border: 1px solid ${COLORS.colorGoldBorder};
`;

const Copy = styled(Typography.Text)`
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`;

const Body = styled.div`
  @media screen and (max-width: 991px) {
    padding-bottom: 102px;
  }
`;

const ActionContainer = styled(Container)`
  padding: 24px 0;
  display: flex;
  align-items: center;

  @media screen and (max-width: 991px) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 102px;
    padding-inline: calc((100% - 390px) / 2);
    background: #fff;
  }
`;
