import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Typography } from 'antd';
import { ItemQuestion } from 'src/_pages/auth/components/item-question';

import { Button } from '@/shared/components';
import { Container, Row } from '@/shared/components/layout';
import { useRegistrationStore } from '@/shared/store/registration.store';

interface IQuestionsProperties {
  setIsAnswersVisible: (argument: boolean) => void;
}

const questions = [
  'securityQuestions.questions.firstPetName',
  'securityQuestions.questions.bestFriendInChildhood',
  'securityQuestions.questions.firstCarModel',
  'securityQuestions.questions.firstVacationAboard',
  'securityQuestions.questions.firstGradeSchoolName',
  'securityQuestions.questions.firstBook',
  'securityQuestions.questions.cityOfFavouriteRestaurant',
];

export interface IQuestion {
  id: number;
  text: string;
}

export type TQuestionsState = IQuestion[];

export const Questions: FC<IQuestionsProperties> = ({
  setIsAnswersVisible,
}) => {
  const [questionsState, setQuestionsState] = useState<TQuestionsState | []>(
    [],
  );
  const { setTwoFactorQuestions } = useRegistrationStore();
  const { t } = useTranslation('signup');

  const saveQuestions = async () => {
    await setTwoFactorQuestions(questionsState);
    setIsAnswersVisible(true);
  };

  return (
    <Container>
      <Typography.Title level={3}>
        {t('securityQuestions.title')}
      </Typography.Title>

      <Container marginTop={24}>
        <Typography.Text>{t('securityQuestions.message')}</Typography.Text>
      </Container>

      <Row direction="column" gapRow={8} marginTop={24}>
        {questions.map((item, index) => (
          <div key={index}>
            <Container marginBottom={12}>
              <ItemQuestion
                questionsState={questionsState}
                setQuestionsState={setQuestionsState}
                text={t(item)}
                id={index}
              />
            </Container>
            <Divider style={{ margin: 0 }} />
          </div>
        ))}
      </Row>

      <Container marginTop={50} paddingBottom={24}>
        <Button
          data-testid={'answerBtn'}
          disabled={questionsState.length !== 3}
          text={t('securityQuestions.submitButton')}
          onClick={() => saveQuestions()}
        />
      </Container>
    </Container>
  );
};
