import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sendGTMEvent } from '@next/third-parties/google';

import { Answers } from '@/_pages/auth/components/account-security/answers';
import { Questions } from '@/_pages/auth/components/account-security/questions';
import { useBeneficiaryLogin } from '@/entities/next-owner/hooks/use-beneficiary-login';
import { ErrorText, ProgressBar } from '@/shared/components';
import { Container, Row } from '@/shared/components/layout';
import {
  ProfileType,
  useRegisterBeneficiaryByInvitationCodeMutation,
  useRegisterMutation,
  useWhoamiLazyQuery,
} from '@/shared/generated/graphql';
import { useAuthStore } from '@/shared/store/auth.store';
import { useRegistrationStore } from '@/shared/store/registration.store';
import { log } from '@/shared/utils/log';

import { ERegisrationStep, StepProps } from './index';

export function Step4({ changeStep }: StepProps): JSX.Element {
  const [isAnswersVisible, setIsAnswersVisible] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState<boolean>(false);
  const [register, { error }] = useRegisterMutation();
  const [registerAsNextOwner] =
    useRegisterBeneficiaryByInvitationCodeMutation();
  const { login } = useBeneficiaryLogin();
  const { setToken, setAccount, setIsBeneficiary } = useAuthStore();
  const {
    firstStepData,
    firstStepBeneficiaryData,
    twoFactorQuestionsAndAnswers,
    publicKey,
    beneficiaryJWT,
  } = useRegistrationStore();
  const [whoami] = useWhoamiLazyQuery();
  const { i18n } = useTranslation();

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const registerUser = async () => {
    if (!btnDisabled) {
      setBtnDisabled(true);

      const authResponse = beneficiaryJWT
        ? beneficiaryJWT.startsWith('brc')
          ? (
              await registerAsNextOwner({
                variables: {
                  input: {
                    invitationCode: beneficiaryJWT,
                    email: firstStepBeneficiaryData.email,
                    password: firstStepBeneficiaryData.password,
                    publicKey,
                    twoFactorQuestions: twoFactorQuestionsAndAnswers
                      ? twoFactorQuestionsAndAnswers
                      : [],
                  },
                },
              })
            ).data?.registerBeneficiaryByInvitationCode
          : await login({
              email: firstStepBeneficiaryData.email,
              password: firstStepBeneficiaryData.password,
              jwt: beneficiaryJWT,
              publicKey,
              twoFactorQuestions: twoFactorQuestionsAndAnswers
                ? twoFactorQuestionsAndAnswers
                : [],
            })
        : (
            await register({
              variables: {
                email: firstStepData.email ? firstStepData.email : '',
                password: firstStepData.password ? firstStepData.password : '',
                firstName: firstStepData.firstName,
                lastName: firstStepData.lastName,
                twoFactorQuestions: twoFactorQuestionsAndAnswers
                  ? twoFactorQuestionsAndAnswers
                  : [],
                publicKey: publicKey ? publicKey : '',
                language: i18n.language,
              },
            })
          ).data?.register;
      log.debug('Registered user', authResponse);
      setToken(authResponse?.token);
      setAccount(authResponse?.account);
      if (authResponse?.token) {
        const profile = await whoami({
          context: {
            headers: {
              authorization: authResponse?.token || 'NO TOKEN ERROR',
            },
          },
        });
        setIsBeneficiary(
          profile.data?.whoami.profile?.type === ProfileType.Beneficiary,
        );
        sendGTMEvent({ event: 'register_step4' });
        changeStep(ERegisrationStep.VERIFY_EMAIL);
      }
      if (!authResponse?.token) {
        setBtnDisabled(false);
        return;
      }
    }
  };

  return (
    <Row justifyContent={'center'}>
      <Container maxWidth={390} width={'100%'} height="100%">
        <ProgressBar steps={9} currentStep={6} />
        <Row
          direction="column"
          justifyContent="space-between"
          paddingX={32}
          marginTop={14}
          height={'90%'}
        >
          {isAnswersVisible ? (
            <>
              <Answers
                changeStep={registerUser}
                setIsAnswersVisible={() => setIsAnswersVisible(false)}
                btnDisabled={btnDisabled}
              />
              {error && (
                <Container marginTop={20}>
                  <ErrorText>{error.message}</ErrorText>
                </Container>
              )}
            </>
          ) : (
            <Questions setIsAnswersVisible={setIsAnswersVisible} />
          )}
        </Row>
      </Container>
    </Row>
  );
}
