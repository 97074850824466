import { FC, useState } from 'react';
import { Checkbox } from 'antd';
import { Typography } from 'antd';
import styled from 'styled-components';

import { TQuestionsState } from '@/_pages/auth/components/account-security/questions';

interface IItemQuestion {
  text: string;
  id: number;
  setQuestionsState: (argument: [] | TQuestionsState) => void;
  questionsState: TQuestionsState | [];
}

interface IActive {
  active?: boolean;
}

export const ItemQuestion: FC<IItemQuestion> = ({
  text,
  id,
  questionsState,
  setQuestionsState,
}) => {
  const [active, setActive] = useState(false);

  const onClickHandler = () => {
    if (active) {
      setActive(false);
      setQuestionsState(
        questionsState.filter((question) => question.id !== id),
      );
    } else if (questionsState.length < 3) {
      setActive(true);
      setQuestionsState([
        ...questionsState,
        {
          id: id,
          text: text,
        },
      ]);
    }
  };

  return (
    <Checkbox
      data-testid={`questionCheckbox${id}`}
      value={active}
      onClick={() => onClickHandler()}
      disabled={!active && questionsState.length === 3}
    >
      <Text active={active}>{text}</Text>
    </Checkbox>
  );
};

const Text = styled(Typography.Text)<IActive>`
  &&& {
    max-width: 85%;
    font-size: 16px;
    ${({ active }) => (active ? 'font-weight: 600' : '')}
  }
`;
