import type { FC } from 'react';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { Button, Divider, Space } from 'antd';
import styled from 'styled-components';

import { useMnemonicPhraseStore } from '@/entities/mnemonic/store/mnemonic-phrase.store';
import { Row } from '@/shared/components/layout';
import { COLORS } from '@/shared/constants/colors';
import { randomString, shuffle } from '@/shared/utils/misc';

import { IBlockMnemonicPhrase, IItemMnemonic, IPhraseWord } from './types';

const checkCurrentNumber = (array?: number[]): number => {
  if (array === undefined || array.length === 0) {
    return 0;
  } else {
    let start = 0;
    // eslint-disable-next-line guard-for-in
    for (const index in array) {
      const x = array[index] - start;
      if (x >= 1) {
        return start;
      }
      start++;
    }
    const result = array.at(-1);
    return result === undefined ? 0 : result + 1;
  }
};

const EnterMnemonicPhrase = forwardRef<HTMLDivElement[]>(
  (_properties, reference) => {
    const {
      fullPhrase,
      setPhraseLeft,
      deleteWordPhraseEntered,
      setCurrentWord,
      deleteCellsFilled,
      cellsFilled,
    } = useMnemonicPhraseStore();
    const array = Array.from({ length: 12 }, (_, index) => index + 1);
    const [triggerUpdate, setTriggerUpdate] = useState<number>(0);

    const deleteWord = (number: number): void => {
      if (
        reference &&
        'current' in reference &&
        reference.current &&
        reference.current[number].textContent !== ''
      ) {
        const wordRef_ = reference.current[number].id;
        const deletedWord = fullPhrase.find((x) => x.id === wordRef_)?.word;
        setPhraseLeft(deletedWord ? deletedWord : '', false, wordRef_);
        reference.current[number].textContent = '';
        // reference.current[number].style.backgroundColor = '#888888';
        deleteWordPhraseEntered(number);
        deleteCellsFilled(number);
        setTriggerUpdate(triggerUpdate + 1);
      }
    };

    useEffect(() => {
      if (triggerUpdate) {
        setCurrentWord(checkCurrentNumber(cellsFilled));
      }
    }, [triggerUpdate]);

    return array.map((_item, index) => (
      <Row
        nowrap
        key={index}
        alignCenter
        gapColumn={8}
        onClick={(): void => deleteWord(index)}
      >
        <Number>{index + 1}</Number>
        <StyledButton
          block
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ref={(element: HTMLDivElement): void => {
            if (reference && 'current' in reference && reference.current)
              reference.current[index] = element;
          }}
        />
      </Row>
    ));
  },
);

EnterMnemonicPhrase.displayName = 'EnterMnemonicPhrase';

const ItemMnemonicToChoose: FC<IItemMnemonic> = ({ text, onClick }) => {
  return (
    <Button type="primary" onClick={onClick}>
      {text}
    </Button>
  );
};

export const ConfirmMnemonicPhrase: FC<IBlockMnemonicPhrase> = ({ phrase }) => {
  const {
    phraseEntered,
    phraseLeft,
    setFullPhrase,
    setPhraseEntered,
    setPhraseLeft,
    deleteWordPhraseLeft,
    setCurrentWord,
    currentWord,
    setCellsFilled,
    cellsFilled,
  } = useMnemonicPhraseStore();
  const wordsEnteredReferences = useRef<HTMLDivElement[] | null>([]);
  const [triggerUpdate, setTriggerUpdate] = useState<number>(0);

  useEffect(() => {
    generatePhraseLeft();
  }, []);

  const generatePhraseLeft = (): void => {
    if (phrase) {
      const shuffledPhrase = shuffle([...phrase]);
      if (phraseLeft.length === 0) {
        for (const [index, item] of shuffledPhrase.entries()) {
          const id = randomString(10);
          setFullPhrase(index, item, false, id);
          setPhraseLeft(item, false, id);
        }
      }
    }
  };

  const enterWord = (word: IPhraseWord): void => {
    if (phraseEntered.length < 12) {
      setPhraseEntered(currentWord, word.word, word.isEntered, word.id);
      if (wordsEnteredReferences && wordsEnteredReferences.current) {
        wordsEnteredReferences.current[currentWord].textContent = word.word;
        wordsEnteredReferences.current[currentWord].id = word.id;
        // wordsEnteredReferences.current[currentWord].style.backgroundColor =
        //   '#000000';
      }
      setCellsFilled(currentWord);
      setTriggerUpdate(triggerUpdate + 1);
      deleteWordPhraseLeft(word.id);
    }
  };
  useEffect(() => {
    if (triggerUpdate > 0) {
      setCurrentWord(checkCurrentNumber(cellsFilled));
    }
  }, [triggerUpdate]);
  return (
    <>
      {phraseEntered ? (
        <WordsGrid>
          <EnterMnemonicPhrase ref={wordsEnteredReferences} />
        </WordsGrid>
      ) : (
        <></>
      )}
      <Divider />
      {phraseLeft ? (
        <Space wrap size={8}>
          {phraseLeft.map((item, index) => (
            <ItemMnemonicToChoose
              key={index}
              text={item.word}
              onClick={(): void => enterWord(item)}
            />
          ))}
        </Space>
      ) : (
        <></>
      )}
    </>
  );
};

const WordsGrid = styled.div`
  display: grid;
  grid-auto-flow: column;
  row-gap: 9px;
  column-gap: 19px;
  grid-template-columns: repeat(2, 143px);
  grid-template-rows: repeat(6, 30px);
`;

const Number = styled.span`
  width: 19px;
  font-size: 14px;
  color: ${COLORS.black};
`;

const StyledButton = styled(Button)``;
