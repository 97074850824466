import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sendGTMEvent } from '@next/third-parties/google';
import { Typography } from 'antd';
import * as process from 'process';

import { useMnemonicPhraseStore } from '@/entities/mnemonic/store/mnemonic-phrase.store';
import { Button, ErrorText, InputCode, ProgressBar } from '@/shared/components';
import { Container, Row } from '@/shared/components/layout';
import { useActivateAccountAfterExternalRegistrationMutation } from '@/shared/generated/graphql';
import { useKeyPairStore } from '@/shared/store/decrypted-keypair.store';
import { useRegistrationStore } from '@/shared/store/registration.store';
import { useSettingsStore } from '@/shared/store/settings.store';
import { getKeyPairFromMnemonic } from '@/shared/utils/misc';
import { ConfirmMnemonicPhrase } from '@/widgets/mnemonic-phrase/confirm-mnemonic-phrase';
import { IPhraseWord } from '@/widgets/mnemonic-phrase/confirm-mnemonic-phrase/types';

import { ERegisrationStep, StepProps } from './';

export function Step3({ changeStep }: StepProps): JSX.Element {
  const [btnDisabled, setBtnDisabled] = useState<boolean>(false);
  const [isCreatePin, setIsCreatePin] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [pinError, setPinError] = useState<boolean>(false);
  const [pinCode, setPinCode] = useState<string>('');
  const [pinCodeRepeat, setPinCodeRepeat] = useState<string>('');

  const { phraseEntered, mnemonicPhrase, cleanMnemonicPhrase } =
    useMnemonicPhraseStore();
  const { setEncryptedMnemonic } = useSettingsStore();
  const { setKeyPair } = useKeyPairStore();

  const [activateAccount] =
    useActivateAccountAfterExternalRegistrationMutation();

  const { t } = useTranslation('signup');
  const { beneficiaryJWT } = useRegistrationStore();

  const comparePhrases = (): boolean => {
    if (process.env.NODE_ENV === 'development') return true;
    const sortedPhraseEntered: IPhraseWord[] = [...phraseEntered].sort(
      (a, b) => a.number! - b.number!,
    );
    const arrayFromPhraseEntered: string[] = [];
    sortedPhraseEntered.forEach((item: IPhraseWord) => {
      arrayFromPhraseEntered.push(item.word);
    });
    return (
      JSON.stringify(arrayFromPhraseEntered) === JSON.stringify(mnemonicPhrase)
    );
  };

  const goToCreateNewPin = () => {
    if (!btnDisabled) {
      setBtnDisabled(true);
      if (comparePhrases()) {
        setError(false);
        setBtnDisabled(false);
        setIsCreatePin(true);
      } else {
        setError(true);
        setBtnDisabled(false);
      }
    }
  };

  const onInputPinCode = (value: string) => {
    setPinCode(value);
  };

  const onInputPinCodeRepeat = (value: string) => {
    setPinCodeRepeat(value);
  };

  const savePin = async () => {
    if (!btnDisabled) {
      setBtnDisabled(true);
      if (pinCode === pinCodeRepeat && mnemonicPhrase) {
        const _encryptedMnemonic = setEncryptedMnemonic(
          mnemonicPhrase.join(' ').trim(),
          pinCode,
        );
        const keyPair = await getKeyPairFromMnemonic(
          _encryptedMnemonic,
          pinCode,
        );
        console.log('kp', keyPair, _encryptedMnemonic);
        if (!keyPair) return;

        if (beneficiaryJWT) {
          setKeyPair(keyPair);
          changeStep(ERegisrationStep.SECRET_QUESTIONS);

          return;
        }

        const activateAccountResult = await activateAccount({
          variables: {
            publicKey: keyPair?.publicKey,
          },
        });

        if (activateAccountResult.data) {
          setKeyPair(keyPair);
          sendGTMEvent({ event: 'register_step3' });
          changeStep(ERegisrationStep.CONGRATILATION);
        }

        cleanMnemonicPhrase();
      } else {
        setPinError(true);
        setBtnDisabled(false);
      }
    }
  };

  return (
    <Row justifyContent={'center'} height="100dvh">
      <Container maxWidth={390} width={'100%'} relative>
        <ProgressBar
          steps={beneficiaryJWT ? 9 : 5}
          currentStep={
            beneficiaryJWT ? (!isCreatePin ? 4 : 5) : !isCreatePin ? 3 : 4
          }
        />
        {/*<BackButton overrideOnclick={() => changeStep(-1)} />*/}
        <Row
          nowrap
          direction="column"
          justifyContent="space-between"
          paddingX={32}
          marginTop={42}
          height="93%"
          paddingBottom={24}
        >
          {!isCreatePin ? (
            <>
              <div>
                <Typography.Title level={3}>
                  {t('confirmMnemonic.title')}
                </Typography.Title>

                <Container marginTop={10}>
                  <Typography.Text>
                    {t('confirmMnemonic.message')}
                  </Typography.Text>
                </Container>
                <Container marginTop={20}>
                  {error && (
                    <ErrorText>
                      {t('confirmMnemonic.errors.wrongOrder')}
                    </ErrorText>
                  )}
                </Container>

                <Row marginTop={62} justifyContent="center">
                  <ConfirmMnemonicPhrase phrase={mnemonicPhrase} />
                </Row>
              </div>

              <Container marginTop={30}>
                <Button
                  data-testid={'continueBtn'}
                  text={t('common.continueBtn')}
                  disabled={phraseEntered.length < 12 || btnDisabled}
                  onClick={() => goToCreateNewPin()}
                />
                {process.env.NODE_ENV === 'development' && (
                  <Container marginTop={24}>
                    <Button
                      text={'Skip'}
                      onClick={() => goToCreateNewPin()}
                      disabled={btnDisabled}
                    />
                  </Container>
                )}
              </Container>
            </>
          ) : (
            <>
              <Container relative zIndex={20}>
                <Typography.Title level={3}>
                  {t('createPin.title')}
                </Typography.Title>

                <Container marginTop={13}>
                  <Typography.Paragraph>
                    {t('createPin.paragraph1')}
                  </Typography.Paragraph>
                  <Typography.Paragraph>
                    {t('createPin.paragraph2')}
                  </Typography.Paragraph>
                </Container>

                <Row
                  direction={'column'}
                  alignItems={'center'}
                  gapRow={24}
                  marginTop={42}
                >
                  <InputCode
                    maxLength={6}
                    onInputPinCode={onInputPinCode}
                    triggerRepeat
                    uniqPostfix={'first'}
                    autofocus={true}
                  />
                  <Typography.Text>
                    {t('createPin.repeatPinLabel')}
                  </Typography.Text>
                  <InputCode
                    maxLength={6}
                    onInputPinCode={onInputPinCodeRepeat}
                    firstInputPostfix={'first'}
                    uniqPostfix={'second'}
                  />
                </Row>
              </Container>

              {pinError ? (
                <Container marginTop={25} relative zIndex={20}>
                  <ErrorText>{t('createPin.errors.mismatch')}</ErrorText>
                </Container>
              ) : null}

              <Container marginTop={25} relative zIndex={20}>
                <Button
                  data-testid={'continueBtn'}
                  text={t('createPin.submitButton')}
                  onClick={() => savePin()}
                  disabled={
                    pinCode.length !== 6 ||
                    pinCodeRepeat.length !== 6 ||
                    btnDisabled
                  }
                />
              </Container>
            </>
          )}
        </Row>
      </Container>
    </Row>
  );
}
