import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sendGTMEvent } from '@next/third-parties/google';
import { Typography } from 'antd';
import CodeCountdown from 'src/shared/components/code-countdown';

import { Button, ErrorText, InputCode, ProgressBar } from '@/shared/components';
import { Container, Row } from '@/shared/components/layout';
import {
  TypeEmailCode,
  useActivateAccountMutation,
  useGenerateEmailCodeMutation,
  useVerifyEmailCodeLazyQuery,
} from '@/shared/generated/graphql';
import { useAuthStore } from '@/shared/store/auth.store';
import { Title } from '@/shared/uikit/Title';

import { ERegisrationStep, StepProps } from './index';

export function Step5({ changeStep }: StepProps): JSX.Element {
  const { t } = useTranslation('signup');

  const { account } = useAuthStore();
  const [activateAccount] = useActivateAccountMutation();
  const [generateEmailCode] = useGenerateEmailCodeMutation();

  const [btnDisabled, setBtnDisabled] = useState(false);
  const [pinCode, setPinCode] = useState('');
  const [error, setError] = useState('');

  const sendEmailCode = useCallback(async () => {
    return await generateEmailCode({
      variables: {
        email: account && account.email ? account.email : '',
        typeEmailCode: TypeEmailCode.Create,
      },
    });
  }, [generateEmailCode]);

  const [verifyCode] = useVerifyEmailCodeLazyQuery();

  const handlePinCode = async () => {
    if (!btnDisabled) {
      setBtnDisabled(true);
      const verificationResult = await verifyCode({
        variables: {
          email: account && account.email ? account.email : '',
          code: pinCode,
        },
      });

      if (!verificationResult.data?.verifyEmailCode) {
        setError('auth:recover.invalid_code');
        setBtnDisabled(false);
        return;
      }

      const resultActivate = await activateAccount({
        variables: {
          email: account && account.email ? account.email : '',
          code: pinCode,
        },
      });

      if (resultActivate.data?.activateAccount) {
        sendGTMEvent({ event: 'register_step5' });
        changeStep(ERegisrationStep.CONGRATILATION);
      }
    }
  };

  useEffect(() => {
    // eslint-disable-next-line promise/catch-or-return,promise/always-return
    sendEmailCode();
  }, []);

  return (
    <Row justifyContent={'center'} height="100dvh">
      <Container maxWidth={390} width={'100%'} height="100%">
        <ProgressBar steps={9} currentStep={7} />
        <Row
          direction="column"
          justifyContent="space-between"
          paddingX={32}
          marginTop={32}
          paddingBottom={24}
          height={'90%'}
        >
          <div>
            <Title level={2}>{t('emailVerification.title')}</Title>

            <Container marginTop={4}>
              <Typography.Title level={5}>
                {t('emailVerification.message')}
              </Typography.Title>
            </Container>

            <Row
              nowrap
              direction="column"
              alignCenter
              marginTop={48}
              gapRow={16}
            >
              <InputCode maxLength={6} onInputPinCode={setPinCode} />
              {error ? <ErrorText>{t(error)}</ErrorText> : null}

              <CodeCountdown sendCode={sendEmailCode} isInitialCall={false} />
            </Row>
          </div>

          <Container marginTop={130}>
            <Button
              data-testid={'continueBtn'}
              text={t('common.continueBtn')}
              disabled={pinCode.length !== 6 || btnDisabled}
              onClick={() => handlePinCode()}
            />
          </Container>
        </Row>
      </Container>
    </Row>
  );
}
