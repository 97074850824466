import { useEffect, useState } from 'react';
import { sendGTMEvent } from '@next/third-parties/google';
import { ProgressBar } from 'src/shared/components/progress-bar';

import { useMnemonicPhraseStore } from '@/entities/mnemonic/store/mnemonic-phrase.store';
import { Disclaimer } from '@/features/registration/mnemonic-phrase/disclaimer';
import { SaveMnemonic } from '@/features/registration/mnemonic-phrase/save-mnemonic';
import { Container, Row } from '@/shared/components/layout';
import { useRegistrationStore } from '@/shared/store/registration.store';

import { ERegisrationStep, StepProps } from './index';

export function Step2({ changeStep }: StepProps): JSX.Element {
  const { isDisclaimerRead, setIsDisclaimerRead } = useMnemonicPhraseStore();
  const [showDisclaimer, setShowDisclaimer] = useState(!isDisclaimerRead);
  const [confirmDisclaimer, setConfirmDisclaimer] = useState(false);
  const [confirmSavedPhrase, setConfirmSavedPhrase] = useState(false);
  const [confirmImpossibleToRecover, setConfirmImpossibleToRecover] =
    useState(false);
  const { beneficiaryJWT } = useRegistrationStore();

  // run only once, and only in browser
  useEffect(() => {
    window.history.pushState({}, document.title, window.location.pathname);
  }, []);

  const hideDisclaimer = () => {
    if (confirmDisclaimer) {
      setShowDisclaimer(false);
      setIsDisclaimerRead(true);
    }
  };

  const nextStep = () => {
    if (confirmSavedPhrase) {
      sendGTMEvent({ event: 'register_step2' });
      changeStep(ERegisrationStep.CREATE_PIN_CODE);
    }
  };

  return (
    <Row justifyContent={'center'} height="100dvh">
      <Container maxWidth={390} width={'100%'} height="100%">
        <ProgressBar
          steps={beneficiaryJWT ? 9 : 5}
          currentStep={
            beneficiaryJWT ? (!showDisclaimer ? 3 : 2) : !showDisclaimer ? 2 : 1
          }
        />
        <Row
          direction="column"
          paddingX={32}
          marginTop={32}
          minHeight="92%"
          justifyContent="space-between"
        >
          {showDisclaimer ? (
            <Disclaimer
              setConfirmDisclaimer={setConfirmDisclaimer}
              confirmDisclaimer={confirmDisclaimer}
              nextStep={hideDisclaimer}
            />
          ) : (
            <SaveMnemonic
              confirmSavedPhrase={confirmSavedPhrase}
              confirmImpossibleToRecover={confirmImpossibleToRecover}
              setConfirmSavedPhrase={setConfirmSavedPhrase}
              setConfirmImpossibleToRecover={setConfirmImpossibleToRecover}
              changeStep={nextStep}
            />
          )}
        </Row>
      </Container>
    </Row>
  );
}
