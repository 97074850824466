import type { FC } from 'react';
import { Button, Typography } from 'antd';
import styled from 'styled-components';

import { Row } from '@/shared/components/layout';

interface IBlockMnemonicPhrase {
  phrase: string[];
}

interface IItemMnemonic {
  text: string;
  number: number;
}

const ItemMnemonic: FC<IItemMnemonic> = ({ text, number }) => {
  return (
    <Row alignCenter gapColumn={8}>
      <Typography.Text style={{ fontSize: 16, width: 19 }}>
        {number}
      </Typography.Text>

      <Button
        data-testid={`mnemonicPhrase${number}`}
        type="primary"
        style={{ cursor: 'default' }}
      >
        {text}
      </Button>
    </Row>
  );
};

export const BlockMnemonicPhrase: FC<IBlockMnemonicPhrase> = ({ phrase }) => {
  return (
    <Grid>
      {phrase.map((item, index) => (
        <ItemMnemonic key={index} number={index + 1} text={item} />
      ))}
    </Grid>
  );
};

const Grid = styled.div`
  display: grid;
  grid-auto-flow: column;
  row-gap: 8px;
  column-gap: 19px;
  grid-template-columns: repeat(2, 143px);
  grid-template-rows: repeat(6, 30px);
`;
