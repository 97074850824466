import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';

const CodeCountdown = ({
  sendCode,
  isInitialCall = false,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sendCode: () => any;
  isInitialCall: boolean;
}) => {
  const Ref = useRef<NodeJS.Timeout | undefined>(undefined);
  const { t } = useTranslation('signup');
  const [timer, setTimer] = useState('00:00');
  const [showText, setShowText] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);

  useEffect(() => {
    clearTimer(getDeadTime());
    setShowText(true);
    setIsCodeSent(true);
    if (isInitialCall) {
      sendCode();
    }
  }, []);

  const handleSendCode = () => {
    sendCode()
      .then(() => {
        clearTimer(getDeadTime());
        setIsCodeSent(true);
        return {};
      })
      .catch(() => {});
  };

  const getTimeRemaining = (e: Date) => {
    const total = Date.parse(e.toString()) - Date.parse(new Date().toString());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e: Date) => {
    const { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        // eslint-disable-next-line sonarjs/no-nested-template-literals
        `${minutes > 9 ? minutes : `0${minutes}`}:${
          seconds > 9 ? seconds : `0${seconds}`
        }`,
      );
    } else {
      setIsCodeSent(false);
    }
  };

  const clearTimer = (e: Date) => {
    setTimer('00:59');
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    const deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 59);
    return deadline;
  };

  return (
    <div>
      {showText && (
        <>
          {isCodeSent ? (
            <>
              <span>{t('You can resend code in')} </span>
              <span>{timer}</span>
            </>
          ) : (
            <>
              <Typography.Link
                onClick={() => handleSendCode()}
                style={{ fontSize: 16 }}
              >
                {t('emailVerification.resendBtn')}
              </Typography.Link>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CodeCountdown;
