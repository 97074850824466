import { Congratulations } from '@/features/registration/congratulations';
import { Step1 } from '@/features/registration/step1';
import { Step2 } from '@/features/registration/step2';
import { Step3 } from '@/features/registration/step3';
import { Step4 } from '@/features/registration/step4';
import { Step5 } from '@/features/registration/step5';
import { ERegisrationStep } from '@/shared/store/registration.store';

export const STEP_MAIN_USER = 4;
export const STEPS_NEXT_OWNER = 6;

// TODO move to store
export { ERegisrationStep } from '@/shared/store/registration.store';

export type StepProps = {
  changeStep: (step: ERegisrationStep) => void;
};

export const Registration: Record<
  ERegisrationStep,
  (props: StepProps) => JSX.Element
> = {
  [ERegisrationStep.ENTER_EMAIL]: Step1,
  [ERegisrationStep.CREATE_SEED]: Step2,
  [ERegisrationStep.CREATE_PIN_CODE]: Step3,
  [ERegisrationStep.SECRET_QUESTIONS]: Step4,
  [ERegisrationStep.VERIFY_EMAIL]: Step5,
  [ERegisrationStep.CONGRATILATION]: Congratulations,
};

// TODO rework
export const isValidRegistrationStep = (
  value: string,
): value is ERegisrationStep => {
  return [
    ERegisrationStep.ENTER_EMAIL,
    ERegisrationStep.CREATE_SEED,
    ERegisrationStep.CREATE_PIN_CODE,
    ERegisrationStep.SECRET_QUESTIONS,
    ERegisrationStep.VERIFY_EMAIL,
    ERegisrationStep.CONGRATILATION,
  ].some((step) => String(step) === value);
};
