import type { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';
import { Button, Typography } from 'antd';

import { Container, Row } from '@/shared/components/layout';

interface IDisclaimer {
  confirmDisclaimer: boolean;
  setConfirmDisclaimer: (disclaimer: boolean) => void;
  nextStep: () => void;
}

export const Disclaimer: FC<IDisclaimer> = ({
  confirmDisclaimer,
  setConfirmDisclaimer,
  nextStep,
}) => {
  const { t } = useTranslation(['signup']);
  return (
    // <Row
    //   nowrap
    //   direction="column"
    //   justifyContent="space-between"
    //   height="100%"
    //   gapRow={12}
    // >
    <>
      <div>
        <Typography.Title level={3}>
          {t('signup:disclaimer.title')}
        </Typography.Title>

        <Container marginTop={10}>
          <Typography.Paragraph>
            <Trans
              i18nKey="signup:disclaimer.paragraph1"
              components={{
                a: (
                  <Typography.Link
                    href="https://owner.one"
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                ),
              }}
            />
          </Typography.Paragraph>
          <Typography.Paragraph>
            {t('signup:disclaimer.paragraph2')}
          </Typography.Paragraph>
          <Typography.Paragraph>
            {t('signup:disclaimer.paragraph3')}
          </Typography.Paragraph>
          <Typography.Paragraph>
            {t('signup:disclaimer.paragraph4')}
          </Typography.Paragraph>
          <Typography.Paragraph>
            {t('signup:disclaimer.paragraph5')}
          </Typography.Paragraph>
        </Container>
      </div>

      <div>
        <Row justifyContent="center" marginBottom={24}>
          <Checkbox
            data-testid={'confirmDisclaimer'}
            onChange={() => setConfirmDisclaimer(!confirmDisclaimer)}
          >
            {t('signup:disclaimer.checkboxText')}
          </Checkbox>
        </Row>

        <Container paddingBottom={24}>
          <Button
            block
            data-testid={'continueBtn'}
            type="primary"
            size="large"
            disabled={!confirmDisclaimer}
            onClick={nextStep}
          >
            {t('signup:common.continueBtn')}
          </Button>
        </Container>
      </div>
    </>
    // </Row>
  );
};
