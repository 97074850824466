import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';
import { useRouter } from 'next/router';
import { yupResolver } from '@hookform/resolvers/yup';
import { sendGTMEvent } from '@next/third-parties/google';
import { Checkbox } from 'antd';
import { Button, Input, Space, Typography } from 'antd';
import styled from 'styled-components';
import * as yup from 'yup';

import { BackButton, ErrorText, ProgressBar } from '@/shared/components';
import { Container, Row } from '@/shared/components/layout';
import { useIsEmailExistLazyQuery } from '@/shared/generated/graphql';
import { useExistInWhitelistLazyQuery } from '@/shared/generated/graphql';
import { useRegistrationStore } from '@/shared/store/registration.store';
import { Title } from '@/shared/uikit/Title';
import { log } from '@/shared/utils/log';

import {
  ERegisrationStep,
  STEP_MAIN_USER,
  StepProps,
  STEPS_NEXT_OWNER,
} from './index';

interface UserInput {
  email: string;
  firstName?: string;
  lastName?: string;
  password: string;
  confirmPassword: string;
  termsOfUse: boolean;
  privacyPolicy: boolean;
}

const validationSchema = yup.object({
  email: yup
    .string()
    .matches(
      /^\S+@\S+\.\S+$/,
      'signup:personalInfo.inputs.email.errors.invalid',
    )
    .required('signup:personalInfo.inputs.email.errors.required'),
  firstName: yup
    .string()
    .trim()
    .required('signup:personalInfo.inputs.firstName.errors.required'),
  lastName: yup
    .string()
    .trim()
    .required('signup:personalInfo.inputs.lastName.errors.required'),
  password: yup
    .string()
    // eslint-disable-next-line sonarjs/no-duplicate-string
    .min(8, 'signup:personalInfo.inputs.password.errors.invalid')
    .matches(
      /^(?=.*[A-Za-z])(?=.*[0-9])(?=.*?[#?!@$%^&*-])/,
      'signup:personalInfo.inputs.password.errors.weakPassword',
    )
    .required('signup:personalInfo.inputs.password.errors.required'),
  confirmPassword: yup
    .string()
    .min(8, 'signup:personalInfo.inputs.repeatPassword.errors.invalid')
    .oneOf(
      [yup.ref('password'), undefined],
      'signup:personalInfo.inputs.repeatPassword.errors.mismatch',
    )
    .required('signup:personalInfo.inputs.repeatPassword.errors.required'),
  termsOfUse: yup
    .boolean()
    .required('signup:personalInfo.checkboxes.termsOfUse.errors.required')
    // eslint-disable-next-line sonarjs/no-duplicate-string
    .oneOf(
      [true],
      'signup:personalInfo.checkboxes.termsOfUse.errors.trueRequired',
    ),
  privacyPolicy: yup
    .boolean()
    .required('signup:personalInfo.checkboxes.privacyPolicy.errors.required')
    .oneOf(
      [true],
      'signup:personalInfo.checkboxes.privacyPolicy.errors.trueRequired',
    ),
});
const validationSchemaBeneficiary = yup.object({
  email: yup
    .string()
    .matches(
      /^\S+@\S+\.\S+$/,
      'signup:personalInfo.inputs.email.errors.invalid',
    )
    .required('signup:personalInfo.inputs.email.errors.required'),
  password: yup
    .string()
    .min(8, 'signup:personalInfo.inputs.password.errors.invalid')
    .matches(
      /^(?=.*[A-Za-z])(?=.*[0-9])(?=.*?[#?!@$%^&*-])/,
      'signup:personalInfo.inputs.password.errors.weakPassword',
    )
    .required('signup:personalInfo.inputs.password.errors.required'),
  confirmPassword: yup
    .string()
    .min(8, 'signup:personalInfo.inputs.repeatPassword.errors.invalid')
    .oneOf(
      [yup.ref('password'), undefined],
      'signup:personalInfo.inputs.repeatPassword.errors.mismatch',
    )
    .required('signup:personalInfo.inputs.repeatPassword.errors.required'),
  termsOfUse: yup
    .boolean()
    .required('signup:personalInfo.checkboxes.termsOfUse.errors.required')
    // eslint-disable-next-line sonarjs/no-duplicate-string
    .oneOf(
      [true],
      'signup:personalInfo.checkboxes.termsOfUse.errors.trueRequired',
    ),
  privacyPolicy: yup
    .boolean()
    .required('signup:personalInfo.checkboxes.privacyPolicy.errors.required')
    .oneOf(
      [true],
      'signup:personalInfo.checkboxes.privacyPolicy.errors.trueRequired',
    ),
});

export function Step1({ changeStep }: StepProps): JSX.Element {
  const { setFirstStepData, beneficiaryJWT, setFirstStepBeneficiaryData } =
    useRegistrationStore();
  const { t } = useTranslation(['signup']);
  const [isEmailExist] = useIsEmailExistLazyQuery();
  const [existInWhitelistLazyQuery] = useExistInWhitelistLazyQuery();
  const [enableShowroomStep, setEnableShowroomStep] = useToggle(false);
  const { query } = useRouter();
  const email = (query?.email as string) ?? '';
  const name = (query?.name as string) ?? '';
  const lastName = (query?.lastName as string) ?? '';

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<UserInput>({
    defaultValues: {
      email,
      firstName: name,
      lastName: lastName,
    },
    resolver: yupResolver(
      beneficiaryJWT ? validationSchemaBeneficiary : validationSchema,
    ),
  });

  const onSubmitHandler = async (values: UserInput) => {
    if (Object.keys(errors).length > 0) {
      log.debug('Error', errors);
      return;
    }

    const result = await existInWhitelistLazyQuery({
      variables: {
        email: values.email.trim(),
      },
    });

    const openShowroomStep = Boolean(!result.data?.existInWhitelist);
    setEnableShowroomStep(openShowroomStep);
    if (openShowroomStep) {
      return;
    }

    log.debug('Registering user', values.email);
    if (beneficiaryJWT) {
      setFirstStepBeneficiaryData(values.email.trim(), values.password.trim());
    } else {
      const isExist = await isEmailExist({
        variables: {
          email: values.email.trim(),
        },
      });
      if (isExist.error || isExist.data?.isEmailExists) {
        setError('email', {
          message: isExist.error?.message || 'Email is already exist',
        });
        return;
      }

      setFirstStepData(
        values.email.trim(),
        values.password.trim(),
        values.firstName!.trim(),
        values.lastName!.trim(),
      );
    }

    sendGTMEvent({ event: 'register_step1' });
    changeStep(ERegisrationStep.CREATE_SEED);
  };

  return (
    <Row justifyContent={'center'} height="100dvh">
      <Container maxWidth={390} width={'100%'}>
        <ProgressBar
          steps={beneficiaryJWT ? STEPS_NEXT_OWNER : STEP_MAIN_USER}
          currentStep={1}
        />
        <Container paddingX={32} marginTop={32} marginBottom={24}>
          {enableShowroomStep ? (
            <Container>
              <BackButton
                overrideOnclick={() => setEnableShowroomStep(false)}
              />

              <Title level={2}>{t('signup:whitelist.title')}</Title>

              <Container marginTop={20}>
                <Typography.Paragraph>
                  {t('signup:whitelist.description')}{' '}
                  <Typography.Link underline href="https://owner.one/en/price">
                    {t('signup:whitelist.linkText')}
                  </Typography.Link>
                </Typography.Paragraph>
              </Container>
            </Container>
          ) : (
            <>
              <Title level={2}>{t('signup:personalInfo.title')}</Title>

              <Container marginTop={4}>
                <Typography.Paragraph>
                  {t('signup:personalInfo.message')}
                </Typography.Paragraph>
              </Container>

              <Container marginTop={24}>
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <Row gapRow={16} direction="column">
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <Space direction="vertical">
                          <Typography.Text>
                            {t('signup:personalInfo.inputs.email.label')}
                          </Typography.Text>
                          <Input
                            placeholder={t(
                              'signup:personalInfo.inputs.email.label',
                            )}
                            type="email"
                            {...field}
                            size="large"
                          />
                          {errors?.email?.message && (
                            <ErrorText>{t(errors.email.message)}</ErrorText>
                          )}
                        </Space>
                      )}
                    />

                    {!beneficiaryJWT && (
                      <>
                        <Controller
                          name="firstName"
                          control={control}
                          render={({ field }) => (
                            <Space direction="vertical">
                              <Typography.Text>
                                {t(
                                  'signup:personalInfo.inputs.firstName.label',
                                )}
                              </Typography.Text>
                              <Input
                                placeholder={t(
                                  'signup:personalInfo.inputs.firstName.label',
                                )}
                                type="text"
                                {...field}
                                size="large"
                              />
                              {errors?.firstName?.message && (
                                <ErrorText>
                                  {t(errors.firstName.message)}
                                </ErrorText>
                              )}
                            </Space>
                          )}
                        />

                        <Controller
                          name="lastName"
                          control={control}
                          render={({ field }) => (
                            <Space direction="vertical">
                              <Typography.Text>
                                {t('signup:personalInfo.inputs.lastName.label')}
                              </Typography.Text>
                              <Input
                                placeholder={t(
                                  'signup:personalInfo.inputs.lastName.label',
                                )}
                                type="text"
                                {...field}
                                size="large"
                              />
                              {errors?.lastName?.message && (
                                <ErrorText>
                                  {t(errors.lastName.message)}
                                </ErrorText>
                              )}
                            </Space>
                          )}
                        />
                      </>
                    )}

                    <Controller
                      name="password"
                      control={control}
                      render={({ field }) => (
                        <Space direction="vertical">
                          <Typography.Text>
                            {t('signup:personalInfo.inputs.password.label')}
                          </Typography.Text>
                          <Input.Password
                            placeholder={t(
                              'signup:personalInfo.inputs.password.label',
                            )}
                            type="password"
                            {...field}
                            size="large"
                          />
                          {errors?.password?.message && (
                            <ErrorText>{t(errors.password.message)}</ErrorText>
                          )}
                          <Typography.Paragraph style={{ fontSize: 12 }}>
                            {t('signup:personalInfo.inputs.password.helpText')}
                          </Typography.Paragraph>
                        </Space>
                      )}
                    />

                    <Controller
                      name="confirmPassword"
                      control={control}
                      render={({ field }) => (
                        <Space direction="vertical">
                          <Typography.Text>
                            {t(
                              'signup:personalInfo.inputs.repeatPassword.label',
                            )}
                          </Typography.Text>
                          <Input.Password
                            placeholder={t(
                              'signup:personalInfo.inputs.repeatPassword.label',
                            )}
                            type="password"
                            {...field}
                            size="large"
                          />
                          {errors?.confirmPassword?.message && (
                            <ErrorText>
                              {t(errors.confirmPassword.message)}
                            </ErrorText>
                          )}
                        </Space>
                      )}
                    />
                  </Row>

                  <Row
                    nowrap
                    justifyContent="center"
                    gapColumn={32}
                    marginTop={86}
                  >
                    <Controller
                      name="termsOfUse"
                      control={control}
                      render={({ field }) => (
                        <Space direction="vertical">
                          <Checkbox {...field}>
                            <StyledLink
                              href="https://owner.one/terms-of-service/"
                              target="_blank"
                            >
                              <Typography.Text>
                                {t(
                                  'signup:personalInfo.checkboxes.termsOfUse.label',
                                )}
                              </Typography.Text>
                            </StyledLink>
                          </Checkbox>
                          {errors?.termsOfUse?.message && (
                            <ErrorText>
                              {t(errors.termsOfUse.message)}
                            </ErrorText>
                          )}
                        </Space>
                      )}
                    />

                    <Controller
                      name="privacyPolicy"
                      control={control}
                      render={({ field }) => (
                        <Space direction="vertical">
                          <Checkbox {...field}>
                            <StyledLink
                              href="https://owner.one/privacy-policy/"
                              target="_blank"
                            >
                              <Typography.Text>
                                {t(
                                  'signup:personalInfo.checkboxes.privacyPolicy.label',
                                )}
                              </Typography.Text>
                            </StyledLink>
                          </Checkbox>
                          {errors?.privacyPolicy?.message && (
                            <ErrorText>
                              {t(errors.privacyPolicy.message)}
                            </ErrorText>
                          )}
                        </Space>
                      )}
                    />
                  </Row>

                  <Container marginTop={30}>
                    <Button
                      block
                      name={'continueBtn'}
                      htmlType="submit"
                      size="large"
                      type="primary"
                    >
                      {t('signup:common.continueBtn')}
                    </Button>
                  </Container>
                </form>
              </Container>
            </>
          )}
        </Container>
      </Container>
    </Row>
  );
}

const StyledLink = styled.a`
  &&& {
    &:hover {
      opacity: 0.6;
    }
  }
`;
