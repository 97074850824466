import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Typography } from 'antd';
import { BackButton } from 'src/shared/components/back-button';
import { Button } from 'src/shared/components/button';
import { ErrorText } from 'src/shared/components/error-text';

import { Container, Row } from '@/shared/components/layout';
import { useRegistrationStore } from '@/shared/store/registration.store';
import { Title } from '@/shared/uikit/Title';

interface IAnswers {
  changeStep: () => void;
  setIsAnswersVisible: () => void;
  btnDisabled: boolean;
}

export const Answers: FC<IAnswers> = ({
  changeStep,
  setIsAnswersVisible,
  btnDisabled,
}) => {
  const [answers, setAnswers] = useState<
    {
      question: string;
      answer: string;
    }[]
  >([]);
  const [error, setError] = useState<boolean>(false);
  const {
    twoFactorQuestions,
    twoFactorQuestionsAndAnswers,
    setTwoFactorQuestionsAndAnswers,
  } = useRegistrationStore();

  const { t } = useTranslation('signup');

  const saveAnswers = (question: string, answer: string) => {
    const array = answers.filter((ans) => ans.question !== question);
    setAnswers([...array, { question: question, answer: answer }]);
  };

  const handleAnswers = async () => {
    if (answers.length === twoFactorQuestions.length) {
      await setTwoFactorQuestionsAndAnswers(answers);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (twoFactorQuestionsAndAnswers.length > 0) {
      changeStep();
    }
  }, [twoFactorQuestionsAndAnswers]);

  return (
    <>
      <div>
        <Container marginLeft={-15}>
          <BackButton overrideOnclick={setIsAnswersVisible} />
        </Container>

        <Title level={2}>{t('securityAnswers.title')}</Title>

        <Container marginTop={4} marginBottom={24} />

        {twoFactorQuestions &&
          twoFactorQuestions.map((question, index) => (
            <Container key={index} marginBottom={16}>
              <Typography.Text>{question.text}</Typography.Text>

              <Row marginTop={4}>
                <Input
                  data-testid={`answerInput${index}`}
                  placeholder={t('securityAnswers.answerInputPlaceholder')}
                  onChange={(e) => saveAnswers(question.text, e.target.value)}
                  maxLength={50}
                />
              </Row>
            </Container>
          ))}
      </div>
      <Row marginTop={50}>
        {error && (
          <ErrorText>{t('securityAnswers.errors.missingAnswers')}</ErrorText>
        )}
      </Row>

      <Container
        marginTop={20}
        paddingBottom={24}
        style={{ justifySelf: 'flex-end' }}
      >
        <Button
          data-testid={'continueBtn'}
          disabled={answers.length !== twoFactorQuestions.length || btnDisabled}
          text={t('common.continueBtn')}
          onClick={() => handleAnswers()}
        />
      </Container>
    </>
  );
};
