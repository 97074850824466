import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import Link from 'next/link';
import CoverImage from '@public/assets/auth/cover-auth-completed.png';
import { Typography } from 'antd';
import styled from 'styled-components';

import { Button } from '@/shared/components';
import { Container, Row } from '@/shared/components/layout';
import { COLORS } from '@/shared/constants/colors';
import { useRegistrationStore } from '@/shared/store/registration.store';
import { Title } from '@/shared/uikit/Title';

export function Congratulations(): ReactElement {
  const { resetStorage } = useRegistrationStore();
  const { t } = useTranslation('signup');
  return (
    <BG data-testid={'mainContainer'}>
      <Row justifyContent={'center'} minHeight="100dvh">
        <Row
          direction="column"
          justifyContent="space-between"
          maxWidth={390}
          width={'100%'}
          paddingBottom={102}
          nowrap
        >
          <Container paddingTop={40} paddingX={32}>
            <Title
              level={2}
              style={{ textAlign: 'center', color: COLORS.white }}
            >
              {t('congratulations.title')}
            </Title>

            <Container marginTop={8}>
              <Typography.Paragraph
                style={{ textAlign: 'center', color: COLORS.white }}
              >
                {t('congratulations.paragraph1')}
              </Typography.Paragraph>

              <Typography.Paragraph
                style={{ textAlign: 'center', color: COLORS.white }}
              >
                {t('congratulations.paragraph2')}
              </Typography.Paragraph>
            </Container>

            {/*<Typography.Paragraph style={{ textAlign: 'center' }}>*/}
            {/*  {t('congratulations.videoGuideMessage')}*/}
            {/*</Typography.Paragraph>*/}
          </Container>

          <Row direction="column">
            <Row
              justifyContent="center"
              marginTop={50}
              style={{ objectFit: 'contain' }}
            >
              <Image src={CoverImage} alt="video-preview" width={390} />
            </Row>
            <ActionContainer paddingY={28} paddingX={32}>
              <Link href={'/dashboard'} shallow style={{ flex: 1 }}>
                <Button
                  data-testid={'startBtn'}
                  type="default"
                  text={t('congratulations.submitButton')}
                  onClick={() => resetStorage()}
                />
              </Link>
            </ActionContainer>
          </Row>
        </Row>
      </Row>
    </BG>
  );
}

const BG = styled.div`
  background-color: ${COLORS.colorPrimary};
`;

const ActionContainer = styled(Container)`
  padding: 24px 0;
  display: flex;
  align-items: center;
  background-color: ${COLORS.colorPrimary};

  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 102px;
  padding-inline: calc((100% - 390px) / 2);
`;
